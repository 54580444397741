

































import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { useComplementaryProducts } from '~/composables';
import ComplementaryProductCart
  from '~/components/molecules/Product/ComplementaryProducts/ComplementaryProductCart/ComplementaryProductCart.vue';
import { getFormattedPrice } from '~/helpers/product/getFormattedPrice';

export default defineComponent({
  name: 'ComplementProducts',
  components: {
    ComplementaryProductCart,
  },
  setup() {
    const { complementaryProducts, complementaryBaseProduct } = useComplementaryProducts();

    const selectedProductPrices = ref<number>(0);

    const baseProductPrice = computed<number>(() => +getFormattedPrice(complementaryBaseProduct.value));

    const totalPrice = computed<number>(() => {
      return baseProductPrice.value + selectedProductPrices.value;
    });

    const onProductSelectionChanged = (price: number) => {
      selectedProductPrices.value += price;
    };

    return {
      totalPrice,
      onProductSelectionChanged,
      complementaryBaseProduct,
      complementaryProducts,
      baseProductPrice,
    };
  },
});
